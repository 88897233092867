<template>

    <router-link to="/identification">
        <ui-fab icon="keyboard_backspace" style="margin-top: 30px;"></ui-fab>
    </router-link>
    
    <p class="text-center">Уважаемый тренер! Мы рекомендуем всем участникам зарегистрироваться на соревнования самостоятельно. Вы можете не переживать за командную скидку, она автоматически рассчитается, как только вся ваша команда окажется в системе. Благодаря такому подходу, участники смогут индивидуально отслеживать статус своей заявки, контролировать процесс оплаты и загрузки музыки, а также это освободит Ваше время. В случае, если Вы все-таки решили подавать заявку одним файлом, Вам необходимо будет скачать и заполнить шаблон, после чего загрузить его обратно. Формат и структуру шаблона менять нельзя, в таком случае система не сможет правильно считать введенные вами данные.</p>

    <section style="text-align: center">
    <router-link to="multiple-signup">
        <button style="background-color: #2F80ED40; max-width: 395px">Подать заявку файлом</button>
    </router-link>
    </section>
    <br>

    <!-- <router-link to="coach-identification">
        <button class="action-button">Отображение моих учеников</button>
    </router-link>
     -->
</template>

<script>

export default {
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style></style>