<template>
    <section class="first-screen">
        <div class="container first-screen__inner">
            <img src="../assets/images/start.svg" alt="illustration" style="margin-top: 95px;">
            <p>{{ this.$store.state.user.name }}, добро пожаловать на&nbsp;всероссийский фестиваль воздушного искусства &laquo;Малиновое небо&raquo;!</p>

            <router-link to="participant">
                Войти в личный кабинет участника
            </router-link>

            <router-link to="coach-start">
                Войти в личный кабинет тренера
            </router-link>    
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.first-screen__inner p {
    font-family: 'Raleway-Medium';
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #1A193B;
    max-width: 630px;
    margin-bottom: 45px;
}

.first-screen__inner a {
    text-align: center;
    background: #2F80ED40;
    border-radius: 8px;
    max-width: 430px;
    width: 100%;
    padding: 10px 30px;
    font-family: 'Raleway-Medium';
    color: rgba(26, 25, 59, 0.8);
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}

.first-screen__inner a:hover{
    background: #2F80ED40;
}

@media (max-width: 395px) { 
    .first-screen__inner p {
        font-size: 14px;
    }
    .first-screen__inner a {
        font-size: 14px;
        padding: 10px 20px;
    }
}
</style>