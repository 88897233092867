<template>
    <section class="second-coach-screen">
        <div class="container second-coach-screen__inner">
            <router-link to="/identification">
                <button class="back__btn">
                    <svg width="81" height="42" viewBox="0 0 81 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21" />
                        <path
                            d="M27.2642 15.75L20.3951 19.6786C20.3951 19.6786 19.3105 20.2471 19.3105 20.75C19.3105 21.2529 20.3951 21.8214 20.3951 21.8214L27.2642 25.75"
                            stroke-width="2.7" stroke-linecap="round" />
                        <path d="M21.4655 20.7498H79.3106" stroke-width="2.7" stroke-linecap="round" />
                    </svg>
                </button>
            </router-link>
            <div class="second-coach-screen__content content">
                <p class="description"> Уважаемый тренер! <br /> <br /> 

                   Первая цель нашего приложения - экономия времени. <br /> <br /> 

                   Поэтому мы рекомендуем всем участникам зарегистрироваться на соревнования самостоятельно и не утруждать тренеров. <br /> <br />

                   А так же Вы сможете видеть все заявки Ваших учеников в этом кабинете (функция отображение моих учеников **в разработке и появится в ближайшее время**).<br /><br/>

                   Если Вы все-же желаете подать заявку файлом, воспользуйтесь соответствующей функцией ниже.
                </p>
                <!-- <router-link class="second-coach-screen__btn secondary__btn" to="check-coach">
                    Отображение моих участников
                </router-link> -->
                <router-link style="max-width: 395px; background-color: rgba(47, 128, 237, 0.25); text-align: center" class="second-coach-screen__btn secondary__btn" to="multiple-signup">
                    Подать заявку файлом
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped>

/* .second-coach-screen__btn {
    max-width: 395px;
} */

.secondary__btn {
    font-family: 'Raleway-Medium';
    background: rgba(213, 70, 196, 0.15);
    color: rgba(26, 25, 59, 0.8);
    font-size: 18px;
    line-height: 28px;
    border-radius: 8px;
    padding: 10px 30px;
    width: 100%;
    margin: 15px 0;
    transition: all 0.3s ease-in-out;
}

.secondary__btn:hover {
    background: rgba(213, 70, 196, 0.30);
}
@media (max-width: 395px) {
    .first-screen__inner a {
        font-size: 14px;
        padding: 10px 20px;
    }
}
</style>