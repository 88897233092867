<template>
  <div class="modal__background" @click="closed"></div>

  <transition name="bounce">

    <div v-if="show" class="cards__pop-ap">
      <div class="cards__pop-ap__inner">

        <button @click="closed" class="cards__pop-ap_close-btn">
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 2L2 32" stroke="gray" stroke-width="2.5" stroke-linecap="round"/>
            <path d="M2 2L32 32" stroke="gray" stroke-width="2.5" stroke-linecap="round"/>
          </svg>
        </button>

        <status :status="performance.status"/>
        <div>
          <div class="cards-subtop" style="display: inline-block">
            <button @click="updateFIO()" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><h2
                class="cards__full_name cards__full_name_pop-up">{{ performance.user.surname }} {{
                performance.user.name
              }} {{ performance.user.patronymic }}
              <img class="update-icon" src="/update.svg" alt=""/>
            </h2></button>
          </div>

        </div>
        <form @submit.prevent="updateUser()">
          <div v-if="showInputFIO">
            <input class="form__input" type="text" placeholder="Фамилия" v-model="artistSurname">
            <input class="form__input" type="text" placeholder="Имя" v-model="artistName">
            <input class="form__input" type="text" placeholder="Отчество" v-model="artistLastname">

            <div style="text-align: center; margin-top: 20px;">
              <button type="submit" class="update-button">Обновить ФИО</button>
            </div>
          </div>
        </form>
        <div>
          <div class="cards-subtop" style="display: inline-block">
            <button @click="updateBirthday()" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><p>Дата рождения:
              {{ performance.user.date_of_birth }}
              <img class="update-icon" src="/update.svg" alt=""/>
            </p>
            </button>
          </div>
          <div>

          </div>
        </div>
        <form @submit.prevent="updateDayBorn()">
          <div v-if="showInputDate">
            <div class="form__group">
              <input type="date" min="1960-06-01" max="2070-06-01" class="form__input" placeholder="дд.мм.гггг"
                     v-model="artistBirthday">
              <label class="form__label">Дата рождения</label>
            </div>

            <div style="text-align: center; margin-top: 20px;">
              <button type="submit" class="update-button">Обновить дату рождения</button>
            </div>
          </div>
        </form>

        <div v-if="showSuccessMessage" class="success-message">
          <p style="color: #000000">Поле успешно обновлено</p>
        </div>
        <ul class="cards__list list cards__list_pop-up">
          <li class="list-item cards-item_pop-up">
            <button @click="updateLevel()" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }">
              <h4 class="cards-item__title list-item__title">Уровень
<!--                  подготовки-->
                <img class="update-icon" src="/update.svg" alt=""/>
              </h4>
            </button>
            <p class="cards-item__descr list-item__descr">{{ performance.level }}</p>
          </li>
          <li v-if="showLevel">
            <form @submit.prevent="updateLevelForm">
              <div>
                <select v-model="selectedLevel">
                  <option value="Дебют">Дебют</option>
                  <option value="Начинающие">Начинающие</option>
                  <option value="Продвинутые">Продвинутые</option>
                  <option value="Профессионалы">Профессионалы</option>
                  <option value="Элита">Элита</option>
                </select>
                <div style="text-align: center; margin-top: 20px;">
                  <button type="submit" class="update-button">Обновить</button>
                </div>
              </div>
            </form>
          </li>
          <li class="list-item cards-item_pop-up" v-if="performance.patner_fio">
            <button @click="updateSOLDUO"><h4 class="cards-item__title list-item__title">ФИО партнера
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.patner_fio }}</p>
          </li>
          <li class="list-item cards-item_pop-up">
            <h4 class="cards-item__title list-item__title">Город выступления</h4>
            <p class="cards-item__descr list-item__descr" v-if="performance.performance_city_id === 1">Сочи, 17-19
              мая</p>
            <p class="cards-item__descr list-item__descr" v-else-if="performance.performance_city_id === 2">
              Санкт-Петербург, 7-9 июня</p>
            <p class="cards-item__descr list-item__descr" v-else>Город выступления не указан</p>
          </li>
          <li class="list-item cards-item_pop-up">
            <button @click="updateNomination()" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><h4
                class="cards-item__title list-item__title">Номинация
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.nomination }}</p>
          </li>
          <li v-if="showNomination">
            <form @submit.prevent="updateNominationForm">
              <div>
                <select v-model="selectedNomination">
                  <option value="Арт">Арт</option>
                  <option value="Спорт">Спорт</option>
                  <option value="Exotic pole">Exotic pole</option>
                </select>
                <div style="text-align: center; margin-top: 20px;">
                  <button type="submit" class="update-button">Обновить</button>
                </div>
              </div>
            </form>
          </li>
          <li class="list-item cards-item_pop-up">
            <button @click="updateProjectile" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><h4
                class="cards-item__title list-item__title">Снаряд
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.projectile.title }}</p>
          </li>
          <li v-if="showProjectile">
            <form @submit.prevent="updateProjectileForm">
              <div>
                <select v-model="selectProjectile_id">
                  <option value="1">Пилон</option>
                  <option value="2">Полотно</option>
                  <option value="3">Кольцо</option>
                  <option value="4">Стропы</option>
                  <option value="5">Петля(гамак)</option>
                  <option value="6">Оригинальный</option>
                </select>
                <div style="text-align: center; margin-top: 20px;">
                  <button type="submit" class="update-button">Обновить</button>
                </div>
              </div>
            </form>
          </li>
          <li class="list-item cards-item_pop-up">
            <button @click="updateSOLDUO" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><h4 class="cards-item__title list-item__title">
              Соло/дуэт
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.solo_or_duet }}</p>
          </li>
          <li v-if="showSOLDUO">
            <select @change="selectDuet" v-model="solo_or_duet">
              <option value="Соло">Соло</option>
              <option value="Дуэт">Дуэт</option>
            </select>
            <form @submit.prevent="updateSOLODUETForm">
              <div v-if="showDuetInputs">
                <input class="form__input" type="text" placeholder="Фамилия" v-model="IpartnerSurname" min="2" style="border-bottom: solid 1px #000000;">
                <input class="form__input" type="text" placeholder="Имя" v-model="IpartnerName" min="2" style="border-bottom: solid 1px #000000;">
                <input class="form__input" type="text" placeholder="Отчество" v-model="IpartnerLastname" min="2" style="border-bottom: solid 1px #000000;">
              </div>
              <div style="text-align: center" v-if="showDuetInputs">
                <p>все поля должны быть заполнены</p>
              </div>
              <div v-if="!showDuetInputs" style="text-align: center; margin-top: 20px;">
                <button type="submit" class="update-button">Обновить</button>
              </div>
              <div v-else style="text-align: center; margin-top: 20px;">
                <button type="submit" class="update-button" :style="{ color: isUpdatingDisabled ? 'black' : 'white' }" :disabled="solo_or_duet === 'Дуэт' && !IpartnerSurname || !IpartnerName || !IpartnerLastname || solo_or_duet === 'Соло'">Обновить</button>
              </div>
            </form>
          </li>
          <li class="list-item cards-item_pop-up">
            <button @click="updateTeam" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><h4 class="cards-item__title list-item__title">
              Участие
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">
              {{ performance.team ? 'Командное участие' : 'участие без команды' }}
            </p>
          </li>
          <li v-if="showTeam">
            <select @change="selectTeam" v-model="school">
              <option value="0">без команды</option>
              <option value="1">Командное Участие</option>
            </select>
            <form @submit.prevent="updateSchoolForm">
              <div v-if="showTeamInputs">
                <input class="form__input" type="text" placeholder="Школа" v-model="schoolName" style="border-bottom: solid 1px #000000;">
              </div>
              <div v-if="!showTeamInputs" style="text-align: center; margin-top: 20px;">
                <button type="submit" class="update-button">Обновить</button>
              </div>
              <div v-else style="text-align: center; margin-top: 20px;">
                <button type="submit" class="update-button" :disabled="!schoolName" :style="{ color: isUpdatingDisabled ? 'black' : 'white' }">Обновить</button>
              </div>
            </form>
          </li>
          <li class="list-item cards-item_pop-up" v-if="performance.school">
            <button @click="updateTeam"><h4 class="cards-item__title list-item__title">Школа
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.school.title }}
            </p>
          </li>
          <li class="list-item cards-item_pop-up" v-if="performance.custom_school && !performance.school">
            <button @click="updateTeam"><h4 class="cards-item__title list-item__title">Школа
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.custom_school.title }}
            </p>
          </li>


          <li class="list-item cards-item_pop-up" v-if="performance.custom_coache && !performance.coach">
            <button @click="updateCustomCoach" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><h4
                class="cards-item__title list-item__title">Тренер
              <img class="update-icon" src="/update.svg" alt=""/>
            </h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.custom_coache.title }}
            </p>
          </li>

          <li class="list-item cards-item_pop-up" v-if="performance.coach">
            <button @click="updateCoach" :disabled="isUpdatingDisabled" :style="{ color: isUpdatingDisabled ? 'black' : 'black' }"><h4 class="cards-item__title list-item__title">
              Тренер<img class="update-icon" src="/update.svg" alt=""/></h4></button>
            <p class="cards-item__descr list-item__descr">{{ performance.coach.surname + ' ' + performance.coach.name }}
            </p>
          </li>

          <li v-if="showCoachInput">
            <form @submit.prevent="updateCoachForm">
              <input class="form__input" type="text" placeholder="Тренер" v-model="coach" style="border-bottom: solid 1px #000000;">
              <div style="text-align: center; margin-top: 20px;">
                <button type="submit" class="update-button" :disabled="!coach" :style="{ color: isUpdatingDisabled ? 'black' : 'white' }">Обновить</button>
              </div>
            </form>
          </li>

          <li v-if="showCustomCoachInput">
            <form @submit.prevent="updateCoachForm">
              <input class="form__input" type="text" placeholder="Тренер" v-model="coach" style="border-bottom: solid 1px #000000;">
              <div style="text-align: center; margin-top: 20px;">
                <button type="submit" class="update-button" :disabled="!coach" :style="{ color: isUpdatingDisabled ? 'black' : 'white' }">Обновить</button>
              </div>
            </form>
          </li>

        </ul>

        <div class="cards__text-for-presenter">
          <label>Текст для ведущего</label>
          <textarea @focus="focused2 = true" v-model="leading_text" name="text-for-presenter" id="10" cols="50" rows="5"
                    placeholder="Напишите, что ведущий должен озвучить перед выступлением. Текст сохраняется автоматически."></textarea>
        </div>


        <div class="cards__text-for-presenter" style="margin-top: 30px;">
          <label>Комментарий для организаторов</label>
          <!--           {{ performance.comment }}-->
          <textarea @focus="focused2 = true" v-model="organizations_comment" name="text-for-presenter" id="10" cols="50"
                    rows="5" placeholder="Напишите, комментарий для ведущего"></textarea>
        </div>

        <div style="display: flex; justify-content: flex-end; margin-top: 15px;">
          <button class="update-button" @click="updateCommentAndLeaderText" v-if="focused2" raised>Сохранить</button>
        </div>
        <!--        <p>Изменение и добавление комментариев приостановлено</p>-->
        <div class="cards__music">
          <h5>Музыка для выступления</h5>
          <div>
            <FadeLoader color="#de49bd" v-if="audioLoaded === true" :size="'100px'"
                        style="margin-right: 150px; display: block;"/>

            <template v-if="performance.audio_confirm === 1">
              Музыка загружена и подтверждена
              <br/>
              <audio v-show="audioLoaded === false" controls style="display: block;">
                <source :src="performance.audio.path" type="audio/ogg">
                <source :src="performance.audio.path" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </template>

            <template v-else>
              <template v-if="performance.audio_access">
                <input type="file" id="file" ref="audio" v-on:change="uploadAudio()"/>
                <label class="cards__music__btn-download">
                  <input type="file" id="file" ref="audio" v-on:change="uploadAudio()"/>
                  Загрузить
                  <div v-if="showMessage && audioLoaded === false" class="popup-message">
                    {{ message }}
                  </div>
                </label>
                <span v-if="performance.audio" style="text-align: center;">
                  <br>
                  <text>Музыка загрузилась</text>
                  <br>
                  <audio v-show="audioLoaded === false" controls style="display: block;">
                    <source :src="performance.audio.path" type="audio/ogg">
                    <source :src="performance.audio.path" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>

                </span>
              </template>
              <template v-else>
                <!--                <p>Загрузка музыки приостановлена, для уточнения вопросов обратитесь к администратору</p>-->
              </template>
            </template>

          </div>
        </div>

        <div class="cards__price cards__price_pop-up" v-if="performance.school || performance.file_or_form === 'form'">
          <h5>Стоимость</h5>
          <h4>{{ performance.price }} ₽</h4>
        </div>

      </div>

    </div>

  </transition>
</template>

<script>
import {FadeLoader} from "vue-spinner/dist/vue-spinner.min.js";
import status from '@/components/Performance/StatusCard.vue'

// import selectPerformanceType from '@/components/Performance/SelectPerformanceType.vue'
import axios from 'axios'

const levels = [
    {
        label: 'Дебют',
        value: 'Дебют',
    },
    {
        label: 'Начинающие',
        value: 'Начинающие',
    },
    {
        label: 'Продвинутые',
        value: 'Продвинутые',
    },
    {
        label: 'Профессионалы',
        value: 'Профессионалы',
    },
    {
        label: 'Элита',
        value: 'Элита',
    },
]
export default {
  props: ['performance'],
  components: {status, FadeLoader},
  data() {

    return {
      levels,
      coach: '',
      showCustomCoachInput: false,
      showCoachInput: false,
      showTeamInputs: false,
      isUpdatingDisabled: false, //поменять на true что бы запретить обновлять данные в карточке
      showProjectile: false,
      showNomination: false,
      showTeam: false,
      showLevel: false,
      showInputDate: false,
      showInputFIO: false,
      showSOLDUO: false,
      showDuetInputs: false,
      isUpdate: false,
      focused1: false,
      focused2: false,
      visible1: true,
      visible2: true,
      leading_text: '',
      organizations_comment: '',
      performanceType: 'не указано',
      show: false,
      audioLoaded: false,
      receiptLoaded: false,
      loaded1: false,
      loaded2: false,
      isVivible: true,
      showMessage: false,
      message: 'Музыка загружена',
      artistSurname: '',
      artistName: '',
      artistLastname: '',
      artistBirthday: '',
      partnerName: '',
      partnerSurname: '',
      partnerLastname: '',
      selectedLevel: 'Дебют',
      showSuccessMessage: false,
      selectedNomination: 'Арт',
      selectProjectile_id: 1,
      solo_or_duet: 'Соло',
      IpartnerSurname: '',
      IpartnerName: '',
      IpartnerLastname: '',
      school: 0,
      schoolName: '',
    }
  },

  setup() {

  },

  created() {

    if (this.performance.comment) {
      this.organizations_comment = this.performance.comment
    }

    if (this.performance.leader_text) {
      this.leading_text = this.performance.leader_text
    }

    this.performanceType = this.performance.type
  },
  mounted() {
    this.getUserPerformances()
    this.show = true
  },
  methods: {
    updateCoachForm() {
      axios.post(`${process.env.VUE_APP_URL}/api/update-coach-card`,{
        performance_id: this.performance.id,
        custom_coach_title: this.coach
      })
          .then(response => {
            console.log(response);
            this.getUserPerformances()
            this.$forceUpdate();
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2000);
          })
          .catch(error => {
            console.error(error);
          });
    },
    updateSchoolForm() {
      const data = {
        performance_id: this.performance.id,
        team_id: this.school,
      };

      if (this.showTeamInputs) {
        data.custom_school = this.schoolName;
      }

      axios.post(`${process.env.VUE_APP_URL}/api/update-team-card`, data)
          .then(response => {
            console.log(response);
            this.getUserPerformances()
            this.$forceUpdate();
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2000);
          })
          .catch(error => {
            console.error(error);
          });
    },
    updateSOLODUETForm() {
      const solo_or_duet = this.solo_or_duet
      const FIO = this.IpartnerSurname + ' ' + this.IpartnerName + ' ' + this.IpartnerLastname;
      axios.post(`${process.env.VUE_APP_URL}/api/update-solo-or-duet-card`, {
        performance_id: this.performance.id,
        solo_or_duet: solo_or_duet,
        patner_fio: FIO
      })
          .then(response => {
            console.log(response);
            this.getUserPerformances()
            this.$forceUpdate();
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2000);
          })
          .catch(error => {
            console.error(error);
          });
    },
    updateProjectileForm() {
      axios.post(`${process.env.VUE_APP_URL}/api/update-projectile-card`, {
        performance_id: this.performance.id,
        projectile_id: this.selectProjectile_id
      })
          .then(response => {
            console.log(response);
            this.getUserPerformances()
            this.$forceUpdate();
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2000);
          })
          .catch(error => {
            console.error(error);
          });
    },
    updateNominationForm() {
      axios.post(`${process.env.VUE_APP_URL}/api/update-nomination-card`, {
        performance_id: this.performance.id,
        nomination: this.selectedNomination
      })
          .then(response => {
            console.log(response);
            this.getUserPerformances()
            this.$forceUpdate();
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2000);
          })
          .catch(error => {
            console.error(error);
          });
    },
    updateLevelForm() {
      axios.post(`${process.env.VUE_APP_URL}/api/update-level-card`, {
        performance_id: this.performance.id,
        level: this.selectedLevel
      })
          .then(response => {
            console.log(response);
            this.getUserPerformances()
            this.$forceUpdate();
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2000);
          })
          .catch(error => {
            console.error(error);
          });
    },


    updateCustomCoach() {
      this.showCustomCoachInput = !this.showCustomCoachInput
    },
    updateCoach() {
      this.showCoachInput = !this.showCoachInput
    },
    selectTeam(event) {
      if (event.target.value === '1') {
        this.showTeamInputs = true;
      } else {
        this.showTeamInputs = false;
      }

      this.$forceUpdate();
    },
    updateTeam() {
      this.showTeam = !this.showTeam
    },
    selectDuet(event) {
      if (event.target.value === 'Дуэт') {
        this.showDuetInputs = true;
      } else {
        this.showDuetInputs = false;
      }
      this.$forceUpdate();
    },
    updateSOLDUO() {
      this.showSOLDUO = !this.showSOLDUO
    },
    getPartnerName(name) {
      this.partnerName = name
    },
    getPartnerSurname(Surname) {
      this.partnerSurname = Surname
    },
    getPartnerLastname(Lastname) {
      this.partnerLastname = Lastname
    },
    updateLevel() {
      this.showLevel = !this.showLevel
    },
    updateNomination() {
      this.showNomination = !this.showNomination
    },
    updateProjectile() {
      this.showProjectile = !this.showProjectile
    },
    async updateUser() {
      axios.post(`${process.env.VUE_APP_URL}/api/update-fio-card`, {
        user_id: this.performance.user.id,
        name: this.artistName,
        surname: this.artistSurname,
        patronymic: this.artistLastname,
        performance_id: this.performance.id
      }).then((res) => {
        this.showInputFIO = false
        console.log(res)
        this.getUserPerformances()
        this.$forceUpdate();
      }).catch((e) => {
        console.log(e)
      })
    },
    async updateDayBorn() {
      axios.post(`${process.env.VUE_APP_URL}/api/update-birth-card`, {
        performance_id: this.performance.id,
        user_id: this.performance.user.id,
        date_of_birth: this.artistBirthday,
      }).then((res) => {
        this.showInputDate = false
        console.log(res)
        this.getUserPerformances()
        this.$forceUpdate();
      }).catch((e) => {
        console.log(e)
      })
    },
    updateFIO() {
      this.showInputFIO = !this.showInputFIO
    },
    updateBirthday() {
      this.showInputDate = !this.showInputDate
    },
    updatePerformanceCard() {
      this.isUpdate = !this.isUpdate;
    },
    hideBlock(performance) {
      if (performance.audio_confirm == 1) {
        return this.isVivible = false;
      }
    },
    updateCommentAndLeaderText() {
      this.updateComment();
      this.updateLeaderText();
    },
    updateComment() {
      axios.post(`${process.env.VUE_APP_URL}/api/performance/update/comment`, {
        performance_id: this.performance.id,
        comment: this.organizations_comment
      })
          .then((res) => {
            this.focused2 = false
            this.getUserPerformances()
            this.closed()
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    updateLeaderText() {
      axios.post(`${process.env.VUE_APP_URL}/api/performance/update/leader-text`, {
        performance_id: this.performance.id,
        leader_text: this.leading_text
      })
          .then((res) => {
            this.focused1 = false
            this.getUserPerformances()
            this.closed()
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    checkIfMp3(str) {
      return str.endsWith('.mp3');
    },
    closed() {
      this.$emit('closed')
    },
    getUserPerformances() {
      // -unique
      axios.post(`${process.env.VUE_APP_URL}/api/user-performance`, {
        // vk_id: 715104636,
        vk_id: localStorage.getItem('vk_user_id'),
      })
          .then((res) => {

            this.$store.state.vkUserPerformances = res.data

            if (this.$store.state.permissionMessageApp) {
              if (this.$store.state.vkUserPerformances.length > 0) {
                this.$router.push('/personal-area')
              } else {
                this.$router.push('/identification')
              }
            }

          })
          .catch((err) => {
            console.log(err)
          })
    },
    getPerformanceType(performanceType) {
      this.performanceType = performanceType
    },
    uploadAudio() {
      this.file = this.$refs.audio.files[0]

      if (!this.checkIfMp3(this.file['name'])) {
        this.$alert('неверный формат, нужен .mp3');
        return false
      }

      this.audioLoaded = true

      console.log(this.file)
      let formData = new FormData();

      const surname = this.performance.user.surname;
      const initials = (this.performance.user.name ? this.performance.user.name[0].toUpperCase() : '') + (this.performance.user.patronymic ? this.performance.user.patronymic[0].toUpperCase() : '');
      const birthDate = new Date(this.performance.user.date_of_birth);
      const age = Math.floor((new Date() - birthDate) / (1000 * 60 * 60 * 24 * 365.25));
      const nomination = this.performance.nomination;
      const projectile = this.performance.projectile.title;
      const solo_or_duet = this.performance.solo_or_duet;

      const duet = this.performance.patner_fio ? this.performance.patner_fio.split(' ') : [];
      const lastName = duet[0] || '';
      const firstName = duet[1] || '';
      const patronymic = duet[2] || '';

      const initialsPartner = (firstName && firstName[0] ? firstName[0].toUpperCase() : '') + (patronymic && patronymic[0] ? patronymic[0].toUpperCase() : '');

      const result = "_" + lastName + '_' + initialsPartner;

      const filename = surname + "_" + initials + "_" + age + "_" + nomination
          + "_" + projectile + "_" + solo_or_duet + (result || "") + ".mp3";

      formData.append('title', filename);
      formData.append('performance_id', this.performance.id);
      formData.append('audio', this.file);
      this.showMessage = true;
      axios.post(`${process.env.VUE_APP_URL}/api/upload-audio`, formData)
          .then((res) => {
            this.loaded1 = true;
            this.audioLoaded = false;
            console.log(res);
            setTimeout(() => {
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
          });

      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },
    upload() {
      this.file = this.$refs.file.files[0]

      // if (!this.checkIfMp3(this.file['name'])) {
      //     this.$alert('неверный формат, нужен .mp3');
      //     return false
      // }
      this.audioLoaded = true

      console.log(this.file)
      let formData = new FormData();
      const config = {'content-type': 'multipart/form-data'}

      formData.append('title', this.file['name']);
      formData.append('performance_id', this.performance.id);
      formData.append('audio', this.file);
      this.showMessage = true;
      axios.post(`${process.env.VUE_APP_URL}/api/upload-audio`,
          formData, config).then((res) => {
        this.$emit('updatedPerformance', true)
        this.audioLoaded = false
        console.log(res)
      }).catch((err) => {
        console.log(err)
      })

      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

.cards__start-of-the-performance {
  display: flex;
  justify-content: space-between;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.cards__music {
  margin-top: 35px;
}

/* .cards__music,
.cards__check,
.cards__start-of-the-performance,
.cards__lighting {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cards__music label,
.cards__check label,
.cards__text-for-presenter label,
.cards__start-of-the-performance label,
.cards__lighting label {
    font-size: 20px;
    line-height: 28px;
    max-width: 250px;
}

.cards__start-of-the-performance select,
.cards__lighting select {
    max-width: 200px;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid rgba(213, 70, 196, 0.35);
    border-radius: 8px;
    padding: 10px 16px;
    font-family: 'Raleway-Medium';
    color: rgba(26, 25, 59, 0.89);
    font-size: 18px;
    line-height: 18px;
}

.cards__music label,
    .cards__check label,
    .cards__start-of-the-performance label,
    .cards__lighting label {
        max-width: 180px;
    }

    .cards__start-of-the-performance select,
    .cards__lighting select {
        max-width: 160px;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 16px;
    }

    .cards__music,
    .cards__check,
    .cards__start-of-the-performance,
    .cards__lighting {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .cards__music label,
    .cards__check label,
    .cards__text-for-presenter label,
    .cards__start-of-the-performance label,
    .cards__lighting label {
        max-width: 290px;
    } */

.cards__music label,
.cards__check label,
.cards__text-for-presenter label,
.cards__start-of-the-performance label,
.cards__lighting label {
  font-size: 20px;
  line-height: 28px;
  max-width: 250px;
}


.cards__text-for-presenter {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cards__text-for-presenter textarea {
  resize: none;
  max-width: 543px;
  background: #2F80ED0D;
  border: 1.5px solid #2F80ED4D;
  border-radius: 8px;
  padding: 10px;
}

.cards__text-for-presenter textarea:focus {
  outline: 1.5px solid #2F80ED;
}

.cards__text-for-presenter textarea,
.cards__text-for-presenter textarea::placeholder {
  font-family: 'Raleway-Regular';
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  line-height: 18px;
}

.cards__text-for-presenter textarea {
  color: #1A193B;
}

.cards__music label,
.cards__check label,
.cards__text-for-presenter label {
  font-size: 17px;
  line-height: 20px;
  max-width: 180px;
}

.cards__music label,
.cards__check label,
.cards__text-for-presenter label,
.cards__start-of-the-performance label,
.cards__lighting label {
  max-width: 290px;
}

.popup-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.update-icon {
  transition: transform 0.2s ease-in-out;
}

.update-icon:active {
  transform: scale(0.8);
}

select {
    width: 100%;
    font-size: 16px;
    color: #000000FF;
    border: none;
    border-bottom: solid 2px #CFCEF380;
    background-color: #ffffff;
    -webkit-background-clip: text;
    cursor: pointer;
    text-align: left;
    padding-bottom: 10px;
}

select:focus {
    outline: none;
    border: none;
    border-bottom: solid 2px #B7CBF4;
}





.update-button {
  background-color: #2F80ED;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 100%;
}

.update-button:hover {
  background-color: white;
  color: black;
  border: 1px solid #2F80ED;
}

.update-button:disabled {
  background-color: #cccccc;
  color: #666666;
  border: none;
  cursor: not-allowed;
}

.success-message {
    position: fixed;
    margin-top: -200px;
    background-color: #ffffff;
    font-size: 8px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px #000000;
    animation: slideIn 0.5s ease-in-out;
    opacity: 1;
    z-index: 899;
    font-weight: 400;
}

@keyframes slideIn {
    from {
        transform: translateX(-20px);
    }
    to {
        transform: translateX(0);
    }
}

.success-message p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
</style>